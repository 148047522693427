import ButtonLink from "components/button-link";
import { useCMSContent } from "components/cms-content-provider";
import SectionHeading from "components/typography/section-heading";
import { useLocale } from "contexts/locale-context";
import { DynamicImageContent } from "declarations/content";
import dropProps from "hoc/drop-props";
import useStaticContent from "hooks/use-static-content";
import icon1 from "images/home/results/1.svg";
import icon2 from "images/home/results/2.svg";
import icon3 from "images/home/results/3.svg";
import icon4 from "images/home/results/4.svg";
import React, { ReactText } from "react";
import styled, { css } from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import themeColor from "styles/theme-color";
import { margin } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    padding-top: 2rem;
    ${zeplinLerp("padding-bottom", 80, 120)};
    background-color: white;

    @media ${desktop.andUp().toString()} {
        border-bottom: 2px solid ${themeColor("borderGrey")};
    }
`;

const Content = styled.div`
    ${contentArea({ default: "1100px" })};
`;

const Header = styled.hgroup`
    ${zeplinLerp("margin-bottom", 54, 64)};
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;
const Heading = styled(SectionHeading)``;

const Date = styled.h3`
    font-size: 1.5rem;
    line-height: 1;
    color: ${themeColor("fontSemiDark")};
`;

const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${zeplinLerp("margin-bottom", 80, 64)};
`;

interface CardProps {
    color: string;
    colorSecondary: string;
    backgroundColor: string;
    icon: string | DynamicImageContent;
    primary: ReactText;
    secondary: ReactText;
}
const Card = (() => {
    const Root = styled(dropProps("color")("div"))<{ color: string }>(
        p => css`
            background-color: ${p.color};
            border-radius: 1rem;
            ${zeplinLerp("--vpadding", 28, 46)};
            ${zeplinLerp("--hpadding", 8, 12)};
            padding: var(--vpadding) var(--hpadding);
            ${zeplinLerp("--size", 148, 230)};
            width: var(--size);
            height: var(--size);
        `,
    );
    const IconWrapper = styled.div`
        ${zeplinLerp("--size", 36, 56)};
        width: var(--size);
        height: var(--size);
        ${margin.centerX};
        ${zeplinLerp("margin-bottom", 10, 16)};
    `;
    const Icon = styled.img`
        width: 100%;
        height: 100%;
    `;
    const Primary = styled(dropProps("color")("h3"))<{ color: string }>(
        p => css`
            color: ${p.color};
            margin-block-start: 0;
            margin-block-end: 0;
            ${zeplinLerp("font-size", 20, 40)};
            ${zeplinLerp("line-height", 38, 64)};
            text-align: center;
        `,
    );
    const Secondary = styled(dropProps("color")("span"))<{ color: string }>(
        p => css`
            display: block;
            color: ${p.color};
            ${zeplinLerp("font-size", 12, 16)};
            ${zeplinLerp("line-height", 13, 22)};
            ${zeplinLerp("letter-spacing", 0.7, 1)};
            text-transform: uppercase;
            text-align: center;
        `,
    );

    function Card(props: CardProps) {
        return (
            <Root color={props.backgroundColor}>
                <IconWrapper>
                    <Icon {...(typeof props.icon === "string" ? { src: props.icon } : props.icon)} />
                </IconWrapper>
                <Primary color={props.color}>{props.primary}</Primary>
                <Secondary color={props.colorSecondary}>{props.secondary}</Secondary>
            </Root>
        );
    }
    return Card;
})();

const CardWrapper = styled.div`
    margin: 0.5rem;
`;

const cardProps = [
    {
        primary: "#ffa437",
        background: "#ffedd7",
        caption: "#a88e6e",
        icon: icon1,
    },
    {
        primary: "#3970ff",
        background: "#dcedff",
        caption: "#7180a6",
        icon: icon2,
    },
    {
        primary: "#ff244b",
        background: "#ffebeb",
        caption: "#a3747c",
        icon: icon3,
    },
    {
        primary: "#82c461",
        background: "#e4f5d6",
        caption: "#8ca57f",
        icon: icon4,
    },
];

const getCardProps = (i: number) => cardProps[i % cardProps.length];

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const Button = styled(ButtonLink)`
    ${p => shadowedButton({ color: p.theme.palette.green })};
    color: white;
    padding: 1.5rem 2.5rem;
    min-width: 200px;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
`;

export default function Results() {
    const staticContent = useStaticContent().home.results;
    const contentState = useCMSContent();
    const locale = useLocale();

    const formatNumber = (x: number) =>
        x.toLocaleString(locale, {
            maximumFractionDigits: 1,
            maximumSignificantDigits: 3,
        });

    const cards =
        contentState.status !== "success"
            ? []
            : [
                  {
                      textPrimary: formatNumber(contentState.content.ContestStatsPlayersCount),
                      textSecondary: staticContent.stats.cyclists,
                  },
                  {
                      textPrimary: formatNumber(contentState.content.ContestStatsRidesCount),
                      textSecondary: staticContent.stats.rides,
                  },
                  {
                      textPrimary: formatNumber(contentState.content.ContestStatsTotalDistanceKm),
                      textSecondary: staticContent.stats.kilometers,
                  },
                  {
                      textPrimary: formatNumber(contentState.content.ContestStatsTotalSavedCO2Kg),
                      textSecondary: staticContent.stats.co2,
                  },
              ];

    return (
        <Root>
            <Content>
                <Header>
                    <Heading>{staticContent.heading}</Heading>
                </Header>
                <Cards>
                    {cards.map((card, i) => (
                        <CardWrapper key={card.textSecondary}>
                            <Card
                                primary={card.textPrimary}
                                secondary={card.textSecondary}
                                icon={getCardProps(i).icon}
                                color={getCardProps(i).primary}
                                backgroundColor={getCardProps(i).background}
                                colorSecondary={getCardProps(i).caption}
                            />
                        </CardWrapper>
                    ))}
                </Cards>
            </Content>
        </Root>
    );
}
