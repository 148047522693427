import AppStore from "components/app-store";
import CleanButton from "components/clean-button";
import { useCMSContent } from "components/cms-content-provider";
import GooglePlay from "components/google-play";
import Markdown from "components/markdown";
import SectionHeading from "components/typography/section-heading";
import useStaticContent from "hooks/use-static-content";
import icon from "images/home/how-to-start/icon.svg";
import itemIcon from "images/home/how-to-start/item-icon.svg";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContestType } from "services/cms-api";
import styled from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    background-color: white;
    ${zeplinLerp("padding-top", 90, 190)};
    ${zeplinLerp("padding-bottom", 150, 70)};
`;

const TextFragment = styled.div``;
const CodeFragment = styled.div``;

const Content = styled.div`
    ${contentArea()};
    display: grid;

    & > ${TextFragment} {
        grid-area: text;
    }

    & > ${CodeFragment} {
        grid-area: code;
    }

    @media ${desktop.andUp().toString()} {
        grid-template-areas: "text code";
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
    }

    @media ${desktop.smallerThanThis().toString()} {
        grid-template-areas:
            "code"
            "text";
        row-gap: 4rem;
    }
`;

const Heading = styled(SectionHeading)``;
const Text = styled(Markdown)``;

const Steps = styled.ol`
    list-style: none;
    counter-reset: step;
    padding-inline-start: 0;
    ${childSpacing({ vertical: "3rem" })};
`;
const Step = styled.li`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    line-height: 2rem;
    position: relative;

    ::before {
        background-image: url(${itemIcon});
        background-size: 100% 100%;
        counter-increment: step;
        content: counter(step);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 60px;
        font-size: 1.5rem;
        line-height: 1;
        color: ${themeColor("green")};
        margin-right: 2rem;
    }

    &:not(:last-of-type)::after {
        content: "";
        position: absolute;
        left: 26px;
        width: 2px;
        height: 3rem;
        top: 100%;
        background-color: ${themeColor("borderGrey")};
    }
`;

const Image = styled.img.attrs({
    src: icon,
})`
    ${zeplinLerp("width", 240, 210)};
`;
const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    ${zeplinLerp("margin-bottom", 80, 32)};
`;

const CodeCopy = styled(CleanButton)`
    font-size: 1rem;
    color: ${themeColor("fontSemiDark")};
    text-align: center;
`;

const CodeField = styled.div`
    display: flex;
    background-color: ${themeColor("backgroundLight")};
    border-radius: 1rem;
    box-shadow: inset 0 1px 3px 0 rgba(210, 216, 224, 0.35);
    ${zeplinLerp("--padding-y", 24, 32)};
    ${zeplinLerp("--padding-x", 24, 40)};
    padding: var(--padding-y) var(--padding-x);
    ${zeplinLerp("margin-bottom", 24, 40)};

    @media ${desktop.andUp().toString()} {
        justify-content: space-between;
    }

    @media ${desktop.smallerThanThis().toString()} {
        justify-content: center;
        & > ${CodeCopy} {
            display: none;
        }
    }
`;
const CodeText = styled.span`
    color: ${themeColor("green")};
    font-size: 2rem;
    line-height: 1;
    font-weight: bold;
`;

const Apps = styled.div`
    & > * {
        vertical-align: middle;
    }

    @media ${desktop.smallerThanThis().toString()} {
        display: flex;
        flex-direction: column;
        align-items: center;
        ${childSpacing({ vertical: "1rem" })};
    }
`;
const GetAppText = styled.span`
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1px;
    color: ${themeColor("fontSemiDark")};
    text-transform: uppercase;
    font-weight: bold;

    @media ${desktop.andUp().toString()} {
        margin-right: 2rem;
    }
`;

export const AppLinks = styled.div`
    display: inline-block;
    ${childSpacing({ horizontal: "1rem" })};
`;

export default function HowToStart() {
    const staticContent = useStaticContent().home.howToStart;
    const cmsContentState = useCMSContent();

    const code = cmsContentState.status === "success" ? cmsContentState.content.Code : "";

    const contestTypeMap = {
        [ContestType.Employer]: "employer" as "employer",
        [ContestType.Open]: "open" as "open",
    };
    return (
        <Root>
            <Content>
                <TextFragment>
                    <Heading>{staticContent.heading}</Heading>
                    <Text>{cmsContentState.status === "success" && cmsContentState.content.HowToStart}</Text>
                    <Steps>
                        {cmsContentState.status === "success" &&
                            staticContent.steps[contestTypeMap[cmsContentState.content.ContestType]].map(step => (
                                <Step key={step}>{step}</Step>
                            ))}
                    </Steps>
                </TextFragment>
                <CodeFragment>
                    <ImageWrapper>
                        <Image />
                    </ImageWrapper>
                    {cmsContentState.status === "success" &&
                        cmsContentState.content.ContestType === ContestType.Employer && (
                            <CodeField>
                                <CodeText>{code}</CodeText>
                                <CopyToClipboard text={code}>
                                    <CodeCopy>{staticContent.accessCodePart.copy}</CodeCopy>
                                </CopyToClipboard>
                            </CodeField>
                        )}
                    <Apps>
                        <GetAppText>{staticContent.accessCodePart.getTheApp}</GetAppText>
                        <AppLinks>
                            <AppStore />
                            <GooglePlay />
                        </AppLinks>
                    </Apps>
                </CodeFragment>
            </Content>
        </Root>
    );
}
