import classNames from "classnames";
import { useCMSContent } from "components/cms-content-provider";
import Markdown from "components/markdown";
import SectionHeading from "components/typography/section-heading";
import useStaticContent from "hooks/use-static-content";
import orangePlants from "images/home/eco-charity/charity-orange.svg";
import orangeSun from "images/home/eco-charity/charity-sun.svg";
import charityIcon from "images/home/eco-charity/charity.svg";
import greenPlants from "images/home/eco-charity/eco-green.svg";
import greenSun from "images/home/eco-charity/eco-sun.svg";
import ecoIcon from "images/home/eco-charity/eco.svg";
import whitePlants from "images/home/eco-charity/white.svg";
import React, { Fragment } from "react";
import { ContestLandingSectionsDTO, SectionTypeConverter } from "services/cms-api";
import styled from "styled-components";
import contentArea from "styles/content-area";
import { desktop, mobile } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    ${zeplinLerp("padding-top", 56, 92)};
    ${zeplinLerp("padding-bottom", 150, 92)};
    position: relative;

    --bg-direction: to right;
    background-image: linear-gradient(var(--bg-direction), var(--bgcolor-primary) 65%, var(--bgcolor-secondary) 65%);
    color: white;

    @media ${desktop.smallerThanThis().toString()} {
        --bg-direction: to bottom;
        text-align: center;
    }

    &.eco {
        --bgcolor-primary: ${themeColor("green")};
        --bgcolor-secondary: #e4f5d6;
    }
    &.charity {
        --bgcolor-primary: ${themeColor("orange")};
        --bgcolor-secondary: #ffedd7;
    }
`;
const TextFragment = styled.div``;
const CardFragment = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    ${contentArea()};
    display: grid;
    position: relative;
    grid-template-areas: "text card";
    column-gap: 7rem;

    & > ${TextFragment} {
        grid-area: text;
    }

    & > ${CardFragment} {
        grid-area: card;
    }

    @media ${desktop.smallerThanThis().toString()} {
        grid-template-areas:
            "text"
            "card";
        justify-items: center;
        row-gap: 2rem;
    }
`;

const IconWrapper = styled.div`
    margin-bottom: 1.5rem;
`;
const Icon = styled.img``;

const Heading = styled(SectionHeading)``;
const Text = styled(Markdown)`
    font-size: 1.125rem;
    line-height: 1.625rem;
`;

const Card = styled.div`
    background-color: white;
    position: relative;
    color: ${themeColor("fontDark")};
    border-radius: 1rem;
    box-shadow: 0 1px 0 0 rgba(163, 173, 186, 0.26), 0 40px 72px 0 rgba(46, 204, 112, 0.1);
    padding: 7rem 2rem;
    text-align: center;
    ${childSpacing({ vertical: "2rem" })};

    width: 340px;
    max-width: 100%;

    @media ${mobile.andDown().toString()} {
        width: 100%;
    }
`;

const CardText = styled.div`
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;
const CardTextPrimary = styled.span`
    font-size: 5rem;
`;
const CardTextSecondary = styled.sup`
    color: ${themeColor("fontSemiLight")};
    font-size: 1.5rem;
    text-transform: uppercase;
    vertical-align: top;
    padding-top: 6px;
`;
const CardTextDivider = styled.div`
    color: ${themeColor("fontSemiLight")};
    font-size: 1.5rem;
`;

const iconMap = {
    none: "",
    eco: ecoIcon,
    charity: charityIcon,
};

const DecorationWhitePlants = styled.img.attrs({
    src: whitePlants,
})`
    position: absolute;
    left: -300px;
    bottom: -120px;
    width: 159px;
    height: 219px;
`;

const DecorationGreenPlants = styled.img.attrs({
    src: greenPlants,
})`
    position: absolute;
    margin: 0;
    right: -66px;
    bottom: -120px;
    width: 109px;
    height: 217px;
`;
const DecorationOrangePlants = styled.img.attrs({
    src: orangePlants,
})`
    position: absolute;
    margin: 0;
    right: -66px;
    bottom: -120px;
    width: 109px;
    height: 217px;
`;

const Decorations = Fragment;

const DecorationOrangeSun = styled.img.attrs({
    src: orangeSun,
})`
    position: absolute;
    right: -200px;
    top: -5px;
    width: 180px;
    height: 88px;
`;

const DecorationGreenSun = styled.img.attrs({
    src: greenSun,
})`
    position: absolute;
    right: -200px;
    top: -5px;
    width: 66px;
    height: 66px;
`;

export default function EcoCharity() {
    const staticContent = useStaticContent().home.ecoCharity;
    const cmsContentState = useCMSContent();
    const isLoaded = cmsContentState.status === "success";
    const isEco =
        cmsContentState.status !== "success" || cmsContentState.content.Section === ContestLandingSectionsDTO.Eco;

    return (
        <Root
            className={classNames(
                (cmsContentState.status === "success" &&
                    SectionTypeConverter.fromDTO(cmsContentState.content.Section)) ||
                    "eco",
            )}
        >
            <Content>
                <Decorations>
                    {isEco && <DecorationGreenSun />}
                    {!isEco && <DecorationOrangeSun />}
                    <DecorationWhitePlants />
                </Decorations>
                <TextFragment>
                    <IconWrapper>
                        <Icon
                            src={
                                iconMap[
                                    cmsContentState.status === "success"
                                        ? SectionTypeConverter.fromDTO(cmsContentState.content.Section)
                                        : "none"
                                ]
                            }
                        />
                    </IconWrapper>
                    <Heading>
                        {cmsContentState.status === "success" &&
                            (cmsContentState.content.Section === ContestLandingSectionsDTO.Eco
                                ? staticContent.ecoHeading
                                : cmsContentState.content.SectionTitle)}
                    </Heading>
                    <Text>
                        {cmsContentState.status === "success" &&
                            (cmsContentState.content.Section === ContestLandingSectionsDTO.Eco
                                ? staticContent.ecoText
                                : cmsContentState.content.SectionSubtitle)}
                    </Text>
                </TextFragment>
                <CardFragment>
                    <Card>
                        <CardText>
                            <CardTextPrimary>
                                {cmsContentState.status === "success" &&
                                    (isEco ? cmsContentState.content.EcoKM : cmsContentState.content.CharityKM)}
                            </CardTextPrimary>
                            <CardTextSecondary>{staticContent.km}</CardTextSecondary>
                        </CardText>
                        <CardTextDivider>{staticContent.divider}</CardTextDivider>
                        <CardText>
                            <CardTextPrimary>
                                {cmsContentState.status === "success" &&
                                    (isEco ? cmsContentState.content.EcoCO2 : cmsContentState.content.CharityMoney)}
                            </CardTextPrimary>
                            <CardTextSecondary>
                                {cmsContentState.status === "success" &&
                                    (isEco ? staticContent.co2 : cmsContentState.content.CharityCurrency)}
                            </CardTextSecondary>
                        </CardText>
                        <Decorations>
                            {isEco && <DecorationGreenPlants />}
                            {!isEco && <DecorationOrangePlants />}
                        </Decorations>
                    </Card>
                </CardFragment>
            </Content>
        </Root>
    );
}
