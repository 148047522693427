import { useCMSContent } from "components/cms-content-provider";
import SectionHeading from "components/typography/section-heading";
import useStaticContent from "hooks/use-static-content";
import femaleIcon from "images/home/contact/female.svg";
import maleIcon from "images/home/contact/male.svg";
import serviceIcon from "images/home/contact/service.svg";
import React from "react";
import { ContestLandingGenderDTO } from "services/cms-api";
import styled from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing, padding } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    background-color: none;
    background-image: linear-gradient(to top, transparent 150px, ${themeColor("backgroundLight")} 150px);
    z-index: 1;
    margin-bottom: -150px;
`;

const Content = styled.div`
    ${contentArea({ default: "1100px" })};
`;

const Header = styled.div`
    ${contentArea({ default: "650px" })};
    text-align: center;
    ${zeplinLerp("margin-bottom", 24, 80)};
`;

const Title = styled.h4`
    ${zeplinLerp("font-size", 16, 20)};
    line-height: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${themeColor("fontGrey")};
    font-weight: normal;
    margin-bottom: 1rem;
`;
const Heading = styled(SectionHeading)``;

const Card = styled.div`
    background-color: white;
    border-radius: 1rem;
    padding-top: 2rem;
    ${padding.x("3rem")};
    padding-bottom: 4rem;
    box-shadow: 0 8px 16px 0 rgba(210, 216, 224, 0.05), 0 1px 0 0 rgba(210, 216, 224, 0.25);
`;

const Cards = styled.div`
    @media ${desktop.smallerThanThis().toString()} {
        ${childSpacing({ vertical: "1.5rem" })};
    }

    @media ${desktop.andUp().toString()} {
        display: flex;
        ${childSpacing({ horizontal: "1.5rem" })};
        & > ${Card} {
            flex: 1;
        }
    }
`;
const CardTitle = styled.h5`
    ${zeplinLerp("font-size", 14, 18)};
    line-height: 1.75;
    text-align: center;
    min-height: calc(2 * 1.75em);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
`;
const CardText = styled.p`
    ${zeplinLerp("font-size", 16, 20)};
    ${zeplinLerp("line-height", 24, 32)};
    margin-bottom: 0;

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;
const CardEmail = styled.a`
    text-decoration: none;
    color: ${themeColor("blue")};
`;
const CardTextContent = styled.div``;
const CardIconWrapper = styled.div`
    height: 85px;

    @media ${desktop.smallerThanThis().toString()} {
        margin-bottom: 1.5rem;
    }
`;
const CardIcon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;
const CardBody = styled.div`
    @media ${desktop.andUp().toString()} {
        display: flex;
        align-items: center;
        ${childSpacing({ horizontal: "2.5rem" })};
    }
`;

const iconMap = {
    none: "",
    male: maleIcon,
    female: femaleIcon,
};

export default function Contact() {
    const staticContent = useStaticContent().home.contact;
    const cmsContentState = useCMSContent();

    const email1 = cmsContentState.status === "success" && cmsContentState.content.CustomerCareEmail;
    const email2 = cmsContentState.status === "success" && cmsContentState.content.CompanyCareEmail;
    return (
        <Root>
            <Content>
                <Header>
                    <Title>{staticContent.heading}</Title>
                    <Heading>{staticContent.text}</Heading>
                </Header>
                <Cards>
                    <Card>
                        <CardTitle>{staticContent.cards.customerCare.heading}</CardTitle>
                        <CardBody>
                            <CardIconWrapper>
                                <CardIcon src={serviceIcon} />
                            </CardIconWrapper>
                            <CardTextContent>
                                <CardText>
                                    <CardEmail href={`mailto:${email1 || ""}`}>{email1}</CardEmail>
                                </CardText>
                                <CardText>{staticContent.cards.customerCare.text}</CardText>
                            </CardTextContent>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardTitle>{staticContent.cards.contact2.heading}</CardTitle>
                        <CardBody>
                            <CardIconWrapper>
                                <CardIcon
                                    src={
                                        iconMap[
                                            cmsContentState.status === "success"
                                                ? cmsContentState.content.CompanyCareGender ===
                                                  ContestLandingGenderDTO.Man
                                                    ? "male"
                                                    : "female"
                                                : "none"
                                        ]
                                    }
                                />
                            </CardIconWrapper>
                            <CardTextContent>
                                <CardText>
                                    {cmsContentState.status === "success" && cmsContentState.content.CompanyCareName}
                                </CardText>
                                <CardText>
                                    <CardEmail href={`mailto:${email2 || ""}`}>{email2}</CardEmail>
                                </CardText>
                            </CardTextContent>
                        </CardBody>
                    </Card>
                </Cards>
            </Content>
        </Root>
    );
}
