import useStaticContent from "hooks/use-static-content";
import appStore from "images/common/app-store-badge-us-black.svg";
import React from "react";
import styled from "styled-components";

const StyledA = styled.a`
    display: inline-block;
`;
const StyledImg = styled.img`
    object-fit: contain;
`;

type AProps = JSX.IntrinsicElements["a"];
export interface AppStoreProps extends Omit<AProps, "href" | "ref"> {}
function AppStore(props: AppStoreProps) {
    const appStoreLink = useStaticContent().common.appLinks.appStore;
    return (
        <StyledA {...props} href={appStoreLink} target="_blank">
            <StyledImg src={appStore} />
        </StyledA>
    );
}
export default AppStore;
