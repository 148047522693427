import DynamicImage from "components/dynamic-image";
import Markdown from "components/markdown";
import Slider from "components/slider";
import { appendDots, mkCustomPaging } from "components/slider-dots";
import useMediaSSR from "hooks/use-media-ssr";
import useStaticContent from "hooks/use-static-content";
import stravaIcon from "images/home/strava/strava.svg";
import garminIcon from "images/home/garmin/garmin.png";
import React, { Children, isValidElement, PropsWithChildren } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import mediaSplit from "styles/media-split";
import { childSpacing, margin } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import mkDynamicImageProps from "utils/mk-dynamic-image-props";

const Root = styled.section`
    ${zeplinLerp("padding-top", 180, 330)};
    ${zeplinLerp("padding-bottom", 64, 92)};
`;

const Icons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${childSpacing({ vertical: "1.25rem" })};
`;

const Icon = styled.img`
    ${zeplinLerp("width", 190, 250)};
    display: block;
`;

const Cards = (() => {
    const DesktopCards = styled.div`
        display: flex;
        ${childSpacing({ horizontal: "1.5rem" })};
    `;

    const MobileCards = styled(({ children, ...props }: Partial<PropsWithChildren<PropsOf<typeof Slider>>>) => (
        <Slider
            {...props}
            children={Children.map(children, child => (
                <SlideWrapper key={isValidElement(child) ? child.key! : undefined}>{child}</SlideWrapper>
            ))}
            touchThreshold={100}
            arrows={false}
            dots
            appendDots={appendDots}
            customPaging={mkCustomPaging({ activeColor: "#ff5d1a", color: "#d2d8e0" })}
            centerMode
            variableWidth
            infinite={false}
        />
    ))`
        .slick-track {
            min-width: 250vw;
        }
    `;

    function Cards(props: PropsWithChildren<{ className?: string }>) {
        const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

        return isMobile ? <MobileCards {...props} /> : <DesktopCards {...props} />;
    }
    return styled(Cards)``;
})();

const Content = styled.div`
    ${contentArea({ default: "1140px" })};
    display: grid;
    & > ${Icon} {
        grid-area: icon;
        align-self: center;
        justify-self: center;
    }
    & > ${Cards} {
        grid-area: cards;
    }

    ${mediaSplit(desktop.minWidth)(
        css`
            grid-template-areas:
                "icon"
                "cards";
            grid-template-columns: 100%;
            row-gap: 2.5rem;
        `,
        css`
            grid-template-areas: "icon cards";
            grid-template-columns: 1fr 2fr;
        `,
    )}
`;

const Card = styled.div`
    padding: 3rem 2rem;
    border-radius: 1rem;
    background-color: white;
    flex: 1;
    min-height: 550px;
    max-width: 316px;
`;
const CardIconWrapper = styled.div`
    height: 250px;
    margin-bottom: 2rem;
`;
const CardIcon = styled(DynamicImage)`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;
const CardText = styled(Markdown)`
    ${zeplinLerp("font-size", 14, 16)};
    line-height: 2;
    text-align: center;
`;

const SlideWrapper = styled.div`
    ${margin.x("0.5rem")};
`;

export default function Strava() {
    const staticContent = useStaticContent().home.strava;
    return (
        <Root>
            <Content>
                <Icons>
                    <Icon src={stravaIcon} />
                    <Icon src={garminIcon} />
                </Icons>
                <Cards>
                    {staticContent.cards.map(card => (
                        <Card key={card.text}>
                            <CardIconWrapper>
                                <CardIcon {...mkDynamicImageProps(card.icon)} />
                            </CardIconWrapper>
                            <CardText>{card.text}</CardText>
                        </Card>
                    ))}
                </Cards>
            </Content>
        </Root>
    );
}
