import { useCMSContent } from "components/cms-content-provider";
import Markdown from "components/markdown";
import SectionHeading from "components/typography/section-heading";
import { format, parseISO } from "date-fns";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import useStaticContent from "hooks/use-static-content";
import itemIcon from "images/home/editions/item-icon.svg";
import React, { useCallback } from "react";
import styled from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    background-color: white;
    position: relative;
    min-height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    ${zeplinLerp("padding-bottom", 80, 120)};
`;

const Content = styled.div`
    ${contentArea()};
    z-index: 1;
`;

const ContentRestriction = styled.div`
    @media ${desktop.andUp().toString()} {
        max-width: 516px;
    }
    @media ${desktop.smallerThanThis().toString()} {
        ${zeplinLerp("padding-top", 500, 600)};
    }
`;

const Heading = styled(SectionHeading)`
    margin-bottom: 1rem;

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const Text = styled(Markdown)`
    ${zeplinLerp("font-size", 16, 18)};
    ${zeplinLerp("line-height", 28, 32)};
    color: ${themeColor("fontGrey")};
    margin-bottom: 4rem;

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const EditionList = styled.ol`
    list-style: none;
    counter-reset: step;
    ${childSpacing({ vertical: "2rem" })};
`;
const EditionListItem = styled.li`
    display: flex;
    align-items: center;

    ::before {
        background-image: url(${itemIcon});
        background-size: 100% 100%;
        counter-increment: step;
        content: counter(step);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 60px;
        font-size: 1.5rem;
        line-height: 1;
        color: ${themeColor("green")};
        margin-right: 2rem;
    }
`;
const EditionListItemContent = styled.div``;
const EditionListItemPrimary = styled.div`
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
`;
const EditionListItemSecondary = styled.div`
    color: ${themeColor("green")};
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2rem;
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    ${zeplinLerp("width", 300, 700)};
    max-width: 100%;
`;
const Image = styled(GatsbyImage)``;

export default function Editions() {
    const staticContent = useStaticContent().home.editions;
    const cmsContentState = useCMSContent();

    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "home/editions/image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    // const formatDate = useCallback((dateString: string): string => format(parseISO(dateString), "dd.MM"), []);
    const formatDate = useCallback((dateString: string): string => {
        const parsed = parseISO(dateString);
        return format(parsed, "dd.MM");
    }, []);

    return (
        <Root>
            <ImageWrapper>
                <Image {...data.image.childImageSharp} />
            </ImageWrapper>
            <Content>
                <ContentRestriction>
                    <Heading>{staticContent.heading}</Heading>
                    <Text>{staticContent.text}</Text>
                    <EditionList>
                        {staticContent.items.map((item, ind) => (
                            <EditionListItem key={ind}>
                                <EditionListItemContent>
                                    <EditionListItemPrimary>{item.header}</EditionListItemPrimary>
                                    <EditionListItemSecondary>{item.description}</EditionListItemSecondary>
                                </EditionListItemContent>
                            </EditionListItem>
                        ))}
                    </EditionList>
                </ContentRestriction>
            </Content>
        </Root>
    );
}
