import useStaticContent from "hooks/use-static-content";
import googlePlay from "images/common/google-play-badge-us.svg";
import React from "react";
import styled from "styled-components";

const StyledA = styled.a`
    display: inline-block;
`;
const StyledImg = styled.img`
    object-fit: contain;
`;

type AProps = JSX.IntrinsicElements["a"];
export interface GooglePlayProps extends Omit<AProps, "href" | "ref"> {}
function GooglePlay(props: GooglePlayProps) {
    const googlePlayLink = useStaticContent().common.appLinks.googlePlay;
    return (
        <StyledA {...props} href={googlePlayLink} target="_blank">
            <StyledImg src={googlePlay} />
        </StyledA>
    );
}
export default GooglePlay;
