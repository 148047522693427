import DynamicImage from "components/dynamic-image";
import Markdown from "components/markdown";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import useStaticContent from "hooks/use-static-content";
import React from "react";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing, contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import mkDynamicImageProps from "utils/mk-dynamic-image-props";
import { AppLinks } from "./how-to-start";
import AppStore from "components/app-store";
import GooglePlay from "components/google-play";

const Root = styled.section`
    overflow: visible;
    background-color: white;

    @media ${desktop.andUp().toString()} {
        border-bottom: 2px solid ${themeColor("borderGrey")};
    }
`;

const BoxFragment = styled.div`
    ${rootCss}
`;

const BoxFragmentInner = styled.div`
    ${contentCss}
`;

const ContentBox = styled.div`
    ${padding.y("80px")};
`;

const Header = styled.hgroup`
    text-align: center;
`;

const Title = styled.h3(
    p => css`
        text-transform: uppercase;
        font-weight: normal;
        color: ${p.theme.palette.green};
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1.6px;
        margin-block-start: 0;
        margin-block-end: 0;

        @media ${desktop.smallerThanThis().toString()} {
            text-align: center;
        }
    `,
);

const Tagline = styled(Markdown)`
    ${zeplinLerp("font-size", 24, 40)};
    ${zeplinLerp("line-height", 38, 72)};
    margin-block-start: 0.5rem;
    p {
        margin: 0;
    }
    strong {
        font-weight: bold;
    }

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const Body = styled.div`
    margin-top: 95px;
    display: flex;
    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column-reverse;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    @media ${desktop.andUp().toString()} {
        min-height: 440px;
    }
    @media ${desktop.smallerThanThis().toString()} {
        min-height: 270px;
    }
    position: relative;
`;

const ImageWrapperInner = styled.div`
    @media ${desktop.andUp().toString()} {
        position: absolute;
        top: 0;
        right: 0;
        width: 125%;
    }
    height: auto;
    @media ${desktop.smallerThanThis().toString()} {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 400px;
        ${margin.centerX};
    }
`;

const List = styled.ul`
    ${childSpacing({ vertical: "3rem" })};
    align-self: center;
    flex: 1;

    @media ${desktop.smallerThanThis().toString()} {
        padding-inline-start: 0;
    }
`;

const ListItemIcon = styled(DynamicImage)`
    width: 32px;
    height: 32px;
    object-fit: contain;
    object-position: center;
`;
const ListItemText = styled.span``;
const ListItem = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    ${childSpacing({ horizontal: "2.5rem" })};
    ${zeplinLerp("font-size", 16, 21)};
    ${zeplinLerp("line-height", 24, 32)};

    & > ${ListItemText} {
        flex: 1;
    }
`;

const StyledAppLinks = styled(AppLinks)`
    width: 100%;
    display: flex;
    justify-content: center;
`;

function ActivyForYou() {
    const staticContent = useStaticContent().home.activyForYou;
    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "home/activy-for-you/img.png" }) {
                childImageSharp {
                    fluid(maxWidth: 791) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Root>
            <BoxFragment>
                <BoxFragmentInner>
                    <ContentBox>
                        <Header>
                            <Title>{staticContent.heading}</Title>
                            <Tagline>{staticContent.text}</Tagline>
                        </Header>
                        <Body>
                            <ImageWrapper>
                                <ImageWrapperInner>
                                    <GatsbyImage {...data.image.childImageSharp} />
                                    {/* <AspectRatio aspectRatio={791 / 1032}>
                                        <img src={image} />
                                    </AspectRatio> */}
                                </ImageWrapperInner>
                            </ImageWrapper>
                            <List>
                                {staticContent.list.map(item => (
                                    <ListItem key={item.text}>
                                        <ListItemIcon {...mkDynamicImageProps(item.icon)} />
                                        <ListItemText>{item.text}</ListItemText>
                                    </ListItem>
                                ))}
                                <StyledAppLinks>
                                    <AppStore />
                                    <GooglePlay />
                                </StyledAppLinks>
                            </List>
                        </Body>
                    </ContentBox>
                </BoxFragmentInner>
            </BoxFragment>
        </Root>
    );
}
export default ActivyForYou;
