import React, { useMemo, ReactNode, forwardRef, Ref } from "react";
import MarkdownIt from "markdown-it";
import mila from "markdown-it-link-attributes";

type DivProps = JSX.IntrinsicElements["div"];
export interface MarkdownProps extends DivProps {
    children?: ReactNode;
    customizeMarkdownIt?: (defaultMd: MarkdownIt) => MarkdownIt;
}
const Markdown = forwardRef(function Markdown(
    { children, customizeMarkdownIt, ...props }: MarkdownProps,
    ref: Ref<HTMLDivElement>,
) {
    const defaultMd = useMemo(
        () =>
            new MarkdownIt({}).use(mila, {
                attrs: {
                    target: "_blank",
                    rel: "noopener",
                },
            }),
        [],
    );

    const md = useMemo(() => (customizeMarkdownIt ? customizeMarkdownIt(defaultMd) : defaultMd), [
        customizeMarkdownIt,
        defaultMd,
    ]);

    if (typeof children !== "string") {
        return <div {...props}>{children}</div>;
    }

    return <div ref={ref} {...props} dangerouslySetInnerHTML={{ __html: md.render(children) }} />;
});
export default Markdown;
