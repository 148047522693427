import { useCMSContent } from "components/cms-content-provider";
import Slider from "components/slider";
import { appendDots, mkCustomPaging } from "components/slider-dots";
import SectionHeading from "components/typography/section-heading";
import useMediaSSR from "hooks/use-media-ssr";
import useStaticContent from "hooks/use-static-content";
import backgroundFlora from "images/home/rules/background-flora.svg";
import backgroundMobile from "images/home/rules/background-mobile.svg";
import background from "images/home/rules/background.svg";
import pointsBg from "images/home/rules/icon-background.svg";
import sun from "images/home/rules/sun.svg";
import React, { ReactText } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing, margin, padding } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import zeplinTypography from "styles/zeplin-typography";

const cardWidth = "336px";

const Root = styled.section`
    background-color: white;
    ${zeplinLerp("padding-top", 32, 72)};
    ${zeplinLerp("padding-bottom", 16, 32)};
`;

const HeaderFragment = styled.div`
    margin-bottom: 5rem;
`;

const MainFragment = styled.div`
    background-color: white;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    color: ${themeColor("fontLight")};

    @media ${desktop.smallerThanThis().toString()} {
        background-position: center 25%;
        background-image: url(${backgroundMobile});
    }
`;

const Content = styled.div`
    padding-top: 10rem;
    padding-bottom: 8rem;

    @media ${desktop.andUp().toString()} {
        ${contentArea({ default: "1140px" })};
    }

    @media ${desktop.smallerThanThis().toString()} {
        > :first-child {
            margin-bottom: 391px;
        }
    }
`;

const Subheading = styled.h3`
    margin-block-start: 0;

    ${zeplinTypography({
        fontSize: 48,
        lineHeight: 72,
    })};

    @media ${desktop.andUp().toString()} {
        /* card's width */
        margin-right: calc(246 / 16 * 1rem);
        /* card's y offset */
        margin-bottom: -3rem;
    }

    @media ${desktop.smallerThanThis().toString()} {
        ${zeplinTypography({
            fontSize: 24,
            lineHeight: 32,
        })}
        text-align: center;
    }
`;

const AlignRightOnDesktop = styled.div`
    @media ${desktop.andUp().toString()} {
        display: flex;
        justify-content: flex-end;
    }
`;

const Cards = styled.div`
    @media ${desktop.andUp().toString()} {
        display: grid;
        grid-template-columns: auto auto;
        gap: calc(24 / 16 * 1rem);

        > :nth-child(odd) {
            position: relative;
            top: calc(105 / 16 * 1rem);
        }
    }
    @media ${desktop.smallerThanThis().toString()} {
        display: flex;
        ${childSpacing({ horizontal: "2rem" })};
        overflow-x: auto;
    }
`;

const Card = styled.div`
    border-radius: 1rem;
    background-color: ${themeColor("darkGreen")};
    width: ${cardWidth};
    min-width: ${cardWidth};
    min-height: 410px;
    box-shadow: 0 8px 16px 0 rgba(210, 216, 224, 0.05);
`;

const CardIcon = (() => {
    const Root = styled.div<{ variant: "sm" | "lg" }>`
        ${p =>
            p.variant === "lg"
                ? css`
                      border-radius: inherit;
                      ${padding.y("3rem")};
                      background-color: white;
                  `
                : css`
                      margin: 2rem;
                      margin-bottom: 0;
                      padding: 3rem;
                      height: 10rem;
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url(${pointsBg});
                  `}
    `;

    const Content = styled.div(
        p => css`
            color: ${themeColor("fontDark")};
            text-align: center;
        `,
    );
    const Points = styled.span`
        display: block;
        font-weight: bold;
        font-size: calc(48 / 16 * 1rem);
        line-height: calc(56 / 48);
    `;
    const PointsText = styled.span(
        p => css`
            display: block;
            color: ${themeColor("fontSemiDark")};
            font-size: calc(16 / 16 * 1rem);
            line-height: calc(20 / 16);
        `,
    );

    interface CardIconProps {
        variant: "sm" | "lg";
        points: ReactText;
        pointsText: string;
    }
    return function CardIcon(props: CardIconProps) {
        return (
            <Root variant={props.variant}>
                <Content>
                    <Points>{props.points}</Points>
                    <PointsText>{props.pointsText}</PointsText>
                </Content>
            </Root>
        );
    };
})();

const CardBody = styled.div`
    padding: 1rem;
`;

const CardBodyText = styled.h3`
    text-align: center;
    font-size: calc(18 / 16 * 1rem);
    line-height: calc(32 / 18);
`;

const FooterInfo = styled.p(
    p => css`
        font-weight: bold;
        ${zeplinTypography({
            fontSize: 14,
            lineHeight: 32,
        })};
        @media ${desktop.andUp().toString()} {
            /* 2x card's width + additional margin */
            margin-right: calc(2 * ${cardWidth} + 4rem);
        }
        @media ${desktop.smallerThanThis().toString()} {
            ${zeplinTypography({
                fontSize: 12,
                lineHeight: 20,
            })};
            max-width: 400px;
            text-align: center;
            ${margin.centerX};
            ${padding.x("2rem")};
        }
    `,
);

const Statics = styled.div`
    position: relative;
    height: 0;
    > * {
        position: absolute;
    }
`;

const Sun = styled.div`
    position: absolute;
    --sizecalc: 89 / 16 * 1rem;
    width: calc(var(--sizecalc));
    height: calc(var(--sizecalc));
    background-image: url(${sun});
    background-size: cover;
    top: calc(var(--sizecalc) * -0.5);
    right: calc(124 / 16 * 1rem);
`;

const Flora = styled.div`
    background-image: url(${backgroundFlora});
    width: 145px;
    height: 289px;
    background-size: 100% 100%;
    right: 124px;
    bottom: -46px;
`;

const StyledSlider = styled(Slider)`
    .slick-track {
        min-width: calc(4 * (${cardWidth} + 2rem));
    }
`;

const CardWrapper = styled.div`
    display: inline;
    ${padding.x("1rem")}
    > :only-child {
        display: inline-block;
    }
`;

function Rules() {
    const staticContent = useStaticContent().home.rules;
    const cmsContentState = useCMSContent();
    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

    const cards =
        cmsContentState.status === "success"
            ? (() => {
                  const content = cmsContentState.content;
                  return [
                      <Card key={"per km"}>
                          <CardIcon
                              variant="sm"
                              points={staticContent.pointsPerKm || content.PointsPerKm}
                              pointsText={staticContent.pointsCaption}
                          />
                          <CardBody>
                              <CardBodyText>{content.PointsPerKmDescription}</CardBodyText>
                          </CardBody>
                      </Card>,
                      <Card key={"per normal ride"}>
                          <CardIcon
                              variant="sm"
                              points={staticContent.pointsPerNormalRide || content.PointsPerNormalRide}
                              pointsText={staticContent.pointsCaption}
                          />
                          <CardBody>
                              <CardBodyText>{content.PointsPerNormalRideDescription}</CardBodyText>
                          </CardBody>
                      </Card>,
                      <Card key={"streak"}>
                          <CardIcon
                              variant="lg"
                              points={staticContent.pointsStreak || content.PointsStreak}
                              pointsText={staticContent.pointsCaption}
                          />
                          <CardBody>
                              <CardBodyText>{content.PointsStreakDescription}</CardBodyText>
                          </CardBody>
                      </Card>,
                      <Card key={"per bonus ride"}>
                          <CardIcon
                              variant="sm"
                              points={staticContent.pointsPerBonusRide || content.PointsPerBonusRide}
                              pointsText={staticContent.pointsCaption}
                          />
                          <CardBody>
                              <CardBodyText>{content.PointsPerBonusRideDescription}</CardBodyText>
                          </CardBody>
                      </Card>,
                  ];
              })()
            : [];

    const isDesktop = useMediaSSR(desktop.andUp().toString(), SSRMQValues);

    return (
        <Root>
            <MainFragment>
                <Statics>{isDesktop && <Sun />}</Statics>
                <Content>
                    <Subheading>{staticContent.subheading}</Subheading>
                    <AlignRightOnDesktop>
                        {isDesktop ? (
                            <Cards>{cards}</Cards>
                        ) : (
                            <StyledSlider
                                arrows={false}
                                infinite={false}
                                variableWidth
                                centerMode
                                swipeToSlide
                                touchThreshold={100}
                                dots
                                appendDots={appendDots}
                                customPaging={mkCustomPaging({
                                    color: "rgba(210, 216, 224, 0.5)",
                                    activeColor: "white",
                                })}
                            >
                                {cards.length === 4 &&
                                    [cards[1], cards[0], cards[3], cards[2]].map(card => (
                                        <CardWrapper key={card.key!}>{card}</CardWrapper>
                                    ))}
                            </StyledSlider>
                        )}
                    </AlignRightOnDesktop>
                    <FooterInfo>
                        {cmsContentState.status === "success" && cmsContentState.content.PointsOverallDescription}
                    </FooterInfo>
                </Content>
                <Statics>{isDesktop && <Flora />}</Statics>
            </MainFragment>
        </Root>
    );
}
export default Rules;
