import AppStore from "components/app-store";
import { useCMSContent } from "components/cms-content-provider";
import DynamicImage from "components/dynamic-image";
import GooglePlay from "components/google-play";
import useStaticContent from "hooks/use-static-content";
import bottomBackground from "images/home/banner/background-bottom.svg";
import sunWithCloud from "images/home/banner/sun-with-cloud.svg";
import React, { Fragment } from "react";
import styled from "styled-components";
import contentArea from "styles/content-area";
import { desktop, mobile } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import mkDynamicImageProps from "utils/mk-dynamic-image-props";

const rootGradient = "linear-gradient(-15deg, #2ede78, #2bbd7c)";

const Root = styled.section`
    padding-top: 120px;
    padding-top: var(--header-height);
    padding-bottom: 4rem;
    background-color: white;
    background-image: url(${bottomBackground}), ${rootGradient};
    background-repeat: no-repeat;
    background-size: 1920px auto, auto auto;
    background-position: center bottom 4rem;

    /* min-height: 100vh; */
    @media (max-height: 720px) {
        min-height: calc(1920 / 1600 * 600px + 120px);
    }

    @media ${desktop.andUp().toString()} {
        padding-bottom: calc(75px + 4rem);
    }
    @media ${desktop.smallerThanThis().toString()} {
        background-size: auto auto;
        background-position: center bottom 8rem;
        background-image: ${rootGradient};
    }
`;

const TextContent = styled.div`
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }

    @media ${desktop.andUp().toString()} {
        position: relative;
        top: -70px;
    }
`;

const Content = styled.div`
    ${contentArea()};
    ${zeplinLerp("padding-top", 36, 48)};
    display: flex;

    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column;
        align-items: center;
        ${childSpacing({ vertical: "1rem" })};
    }

    @media ${desktop.andUp().toString()} {
        height: 100%;
        align-items: center;
        ${childSpacing({ horizontal: "2rem" })};
        ${TextContent} {
            flex: 1;
        }
    }
`;

const CardContent = styled.div`
    height: 100%;
`;

const Card = styled.div`
    position: relative;
    background-color: white;
    box-shadow: 0 24px 40px 0 rgba(163, 173, 186, 0.1);
    border-radius: 1rem;
    padding: 4.5rem 2rem;
    width: 426px;
    max-width: 100%;
    min-height: 426px;
    display: flex;
    flex-direction: column;

    @media ${mobile.andDown().toString()} {
        width: 100%;
    }
`;

const OrganizedBy = styled.h5`
    text-transform: uppercase;
    color: ${themeColor("fontSemiLight")};
    font-size: 0.875rem;
    letter-spacing: 1.17px;
    margin-bottom: 2.5rem;
    text-align: center;
`;

const CompanyLogoWrapper = styled.div`
    flex: 1;
`;
const CompanyLogo = styled(DynamicImage)`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

const SmallTitle = styled.h5`
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
`;

const Title = styled.h1`
    color: white;
    ${zeplinLerp("font-size", 48, 56)};
    ${zeplinLerp("line-height", 48, 80)};
`;
const Date = styled.h2`
    color: rgba(255, 255, 255, 0.75);
    ${zeplinLerp("font-size", 24, 32)};
    line-height: 2.5rem;
`;

const AppLinks = styled.div`
    ${childSpacing({ horizontal: "1rem" })};
`;

const SunWithCloud = styled.img.attrs({
    src: sunWithCloud,
})`
    position: absolute;
    top: 22px;
    right: -92px;
    width: 124px;
    height: 64px;
`;

const Decorations = Fragment;

export default function Banner() {
    const staticContent = useStaticContent().home.banner;
    const contentState = useCMSContent();
    return (
        <Root>
            <Content>
                <TextContent>
                    <SmallTitle>{staticContent.competition}</SmallTitle>
                    <Title>{contentState.status === "success" && contentState.content.ContestTitle}</Title>
                    <Date>{contentState.status === "success" && contentState.content.ContestSubtitle}</Date>
                    <AppLinks>
                        <AppStore />
                        <GooglePlay />
                    </AppLinks>
                </TextContent>
                <CardContent>
                    <Card>
                        <OrganizedBy>{staticContent.organizedBy}</OrganizedBy>
                        <CompanyLogoWrapper>
                            <CompanyLogo
                                {...(() => {
                                    const img = contentState.status === "success" && contentState.content.Logo;
                                    return img && mkDynamicImageProps(img);
                                })()}
                            />
                        </CompanyLogoWrapper>
                        <Decorations>
                            <SunWithCloud />
                        </Decorations>
                    </Card>
                </CardContent>
            </Content>
        </Root>
    );
}
