import React from "react";
import fixLocalLink from "utils/fix-local-link";

type ImageProps = JSX.IntrinsicElements["img"];

export interface DynamicImageProps extends Pick<ImageProps, Exclude<keyof ImageProps, "srcset">> {
    availableSizes?: {
        src: string;
        width: number;
    }[];
}
function DynamicImage({ availableSizes, src, ...props }: DynamicImageProps) {
    const srcset = (availableSizes || []).map(e => `${fixLocalLink(e.src)} ${e.width}w`).join(", ");
    const actualSrc = src && fixLocalLink(src);

    return <img {...props} src={actualSrc} srcSet={srcset} />;
}
export default DynamicImage;
