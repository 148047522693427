import Layout from "components/layout";
import SEO from "components/seo";
import ActivyForYou from "parts/home/activy-for-you";
import Banner from "parts/home/banner";
import Contact from "parts/home/contact";
import EcoCharity from "parts/home/eco-charity";
import Editions from "parts/home/editions";
import HowToStart from "parts/home/how-to-start";
import Results from "parts/home/results";
import Rules from "parts/home/rules";
import Strava from "parts/home/strava";
import WhatIsThis from "parts/home/what-is-this";
import React from "react";
import styled from "styled-components";

const StyledLayout = styled(Layout)`
    z-index: 1;
`;

export const IndexPage = props => {
    return (
        <>
            <SEO title="Home" />
            <Banner />
            <Results />
            <WhatIsThis />
            <HowToStart />
            <Rules />
            <Editions />
            <EcoCharity />
            <ActivyForYou />
            <Strava />
            <Contact />
        </>
    );
};

export default IndexPage;
