import dropProps from "hoc/drop-props";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import themeTransition from "styles/theme-transition";
import { childSpacing, margin } from "styles/utils";

const Root = styled.div`
    ${childSpacing({ horizontal: "0.75rem" })};
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    ${margin.y("2rem")};
`;

export const appendDots = (dots?: ReactNode) => <Root>{dots}</Root>;

const CustomPaging = styled(dropProps("color", "activeColor")("div"))<{ color?: string; activeColor?: string }>`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${p => p.color};
    background-color: var(--custom-paging-bgcolor, ${p => p.color});
    transition: all ${themeTransition()};

    .slick-active & {
        background-color: ${p => p.activeColor};
        background-color: var(--custom-paging-bgcolor-active, ${p => p.activeColor});
    }
`;
CustomPaging.defaultProps = {
    ...CustomPaging.defaultProps,
    color: "grey",
    activeColor: theme.palette.blue,
};

export const customPaging = (_index: number) => <CustomPaging />;

export const mkCustomPaging = ({ color, activeColor }: { color?: string; activeColor?: string }) => (
    _index: number,
) => <CustomPaging color={color} activeColor={activeColor} />;
