import CleanButton from "components/clean-button";
import DynamicImage from "components/dynamic-image";
import Slider from "components/slider";
import { appendDots, mkCustomPaging } from "components/slider-dots";
import SectionHeading from "components/typography/section-heading";
import { useLocale } from "contexts/locale-context";
import { DynamicImageContent } from "declarations/content";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import useMediaSSR from "hooks/use-media-ssr";
import useStaticContent from "hooks/use-static-content";
import useTheme from "hooks/use-theme";
import arrowLeft from "images/home/what-is-this/arrow-left.svg";
import React from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, margin, padding } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import zeplinTypography from "styles/zeplin-typography";
import mkDynamicImageProps from "utils/mk-dynamic-image-props";

const cardWidth = "13rem";
const cardHeight = "18rem";

const ImageFragment = styled.div``;
const TextFragment = styled.div``;

const Root = styled.section`
    ${zeplinLerp("padding-top", 56, 126)};
    ${zeplinLerp("padding-bottom", 32, 64)};
`;

const Content = styled.div`
    display: grid;
    grid-template-areas:
        "text"
        "image";
    row-gap: 2rem;

    ${ImageFragment} {
        grid-area: image;

        @media ${desktop.andUp().toString()} {
            justify-self: flex-end;
        }
    }
    ${TextFragment} {
        grid-area: text;
    }
    @media ${desktop.andUp().toString()} {
        grid-template-areas: "image text";
        grid-template-columns: 450px calc(3 * (${cardWidth} + 2rem));
        column-gap: 2.5rem;
        ${contentArea()};
    }
`;

const LittleTitle = styled.h5`
    color: ${themeColor("blue")};
`;

const Card = (() => {
    const Root = styled.div(
        ({ theme }) => css`
            --border-radius: 0.625rem;
            width: ${cardWidth};
            height: ${cardHeight};
            min-width: ${cardWidth};
            min-height: ${cardHeight};
            box-shadow: 0 8px 16px 0 rgba(210, 216, 224, 0.05), 0 1px 0 0 rgba(210, 216, 224, 0.25);
            border-radius: var(--border-radius);
            background-color: white;
            display: flex;
            flex-direction: column;
            > :first-child {
                flex: 0 0 auto;
            }
            > :last-child {
                flex: 1 1 0;
            }
            > * {
                padding: calc(2 * var(--border-radius));
            }
        `,
    );

    const Header = styled.div(
        ({ theme }) => css`
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
            background-image: linear-gradient(var(--slide-color) 6rem, transparent 6rem);
            height: 10rem;
            padding: 2rem;
            padding-bottom: 0;
        `,
    );

    const Body = styled.div`
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    `;
    const BodyText = styled.p`
        text-align: center;
        font-weight: bold;
        ${zeplinTypography({
            fontSize: 16,
            lineHeight: 26,
        })};
    `;

    const Icon = styled(DynamicImage)`
        width: 100%;
        height: auto;
    `;

    return function Card(props: { icon: string | DynamicImageContent; text: string }) {
        return (
            <Root>
                <Header>
                    <Icon {...mkDynamicImageProps(props.icon)} />
                </Header>
                <Body>
                    <BodyText>{props.text}</BodyText>
                </Body>
            </Root>
        );
    };
})();

const Slide = styled.div`
    @media ${desktop.smallerThanThis().toString()} {
        ${padding.x("0.5rem")};
    }
`;

const Cards = styled.div(
    p => css`
        display: flex;
        justify-content: space-between;
        ${childSpacing({ horizontal: "1rem" })};
        @media ${p.theme.mq.anyMobile.toString()} {
            height: calc(${cardHeight} + 12px);
            overflow-x: auto;
            ${padding.x("2rem")};
        }
    `,
);

const TextContent = styled.div`
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const ImageWrapper = styled.div`
    ${zeplinLerp("width", 340, 500)};
    ${zeplinLerp("height", 500, 700)};
    ${margin.centerX};
`;
const Image = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
`;

const SlideHeading = styled.h3``;

const StyledSlider = styled(Slider)`
    && {
        display: grid;
        grid-template-areas:
            "slider slider slider"
            "arrow-left dots arrow-right";
        grid-template-columns: auto 1fr auto;
        row-gap: 2rem;

        & > .slick-list {
            grid-area: slider;
        }
        & > .slick-prev {
            grid-area: arrow-left;
        }
        & > .slick-next {
            grid-area: arrow-right;
        }
        & > .slick-dots {
            grid-area: dots;
        }

        @media ${desktop.andUp().toString()} {
            width: calc(3 * (${cardWidth} + 1rem) - 1rem);
        }
    }
`;

const SliderArrow = styled(CleanButton)`
    min-width: ${cardWidth};
    display: flex !important;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    transition: all ${themeTransition()};
    border-radius: 0.625rem;
    border: 1px solid ${themeColor("borderGrey")};
    font-size: 1rem;
    line-height: 1;

    &:hover {
        background-color: white;
    }
`;

const ArrowPrev = styled(SliderArrow)``;

const InnerArrowLeft = styled.img.attrs({
    src: arrowLeft,
})``;

const InnerArrowRight = styled(InnerArrowLeft)`
    transform: scaleX(-1);
`;

const ArrowNext = styled(SliderArrow)``;

export default function WhatIsThis() {
    const staticContent = useStaticContent().home.whatIsThis;
    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);
    const data = useStaticQuery(graphql`
        query {
            imageGdansk: file(relativePath: { eq: "home/what-is-this/image-pl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            imageSopot: file(relativePath: { eq: "home/what-is-this/image-en.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const theme = useTheme();
    const locale = useLocale();

    const slides = isMobile
        ? staticContent.slides
              .flatMap((slide, slideIndex) => slide.cards.map(card => ({ ...card, color: slide.color, slideIndex })))
              .map(({ slideIndex, color, ...card }) => (
                  <Slide key={card.text}>
                      <div style={{ "--slide-color": color } as any}>
                          <Card {...card} />
                      </div>
                  </Slide>
              ))
        : staticContent.slides.map((slide, index) => (
              <Slide key={slide.heading}>
                  <SlideHeading>{slide.heading}</SlideHeading>
                  <Cards style={{ "--slide-color": slide.color } as any}>
                      {slide.cards.map(card => (
                          <Card key={card.text} {...card} />
                      ))}
                  </Cards>
              </Slide>
          ));

    return (
        <Root>
            <Content>
                <TextFragment>
                    <TextContent>
                        <SectionHeading>{staticContent.heading}</SectionHeading>
                        <LittleTitle>{staticContent.littleTitle}</LittleTitle>
                    </TextContent>
                    <StyledSlider
                        arrows={!isMobile}
                        infinite={false}
                        dots
                        appendDots={appendDots}
                        customPaging={mkCustomPaging({ activeColor: theme.palette.green, color: "#e2e6eb" })}
                        touchThreshold={100}
                        variableWidth
                        centerMode={isMobile}
                        prevArrow={
                            <ArrowPrev>
                                <InnerArrowLeft />
                                {staticContent.prevButton}
                            </ArrowPrev>
                        }
                        nextArrow={
                            <ArrowNext>
                                {staticContent.nextButton}
                                <InnerArrowRight />
                            </ArrowNext>
                        }
                    >
                        {slides}
                    </StyledSlider>
                </TextFragment>
                <ImageFragment>
                    <ImageWrapper>
                        <Image {...(locale === "gdansk" ? data.imageGdansk : data.imageSopot).childImageSharp} />
                    </ImageWrapper>
                </ImageFragment>
            </Content>
        </Root>
    );
}
