import { createElement, ElementType } from "react";

export default function dropProps<P extends string[]>(...propNames: P) {
    function dropProps2<Component extends ElementType<any>>(component: Component) {
        type NewProps = Omit<PropsOf<Component>, P[number]>;
        function dropProps3(props: PropsOf<Component>) {
            const newProps = Object.keys(props)
                .filter(key => !propNames.includes(key))
                .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {} as NewProps);
            return createElement(component, newProps);
        }
        dropProps3.displayName = `dropProps[${propNames.join()}]`;
        return dropProps3;
    }
    return dropProps2;
}
